<template>
  <module-container route-name="products" show-toolbar :title="$t('products')">
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <product-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";

import ProductFilters from "@/modules/products/components/ProductFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { ProductFilters },
})
export default class ProductsIndex extends Mixins(PaginateProviderMixin) {}
</script>
